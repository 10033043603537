<template>
  <div class="top-menu">
    <!-- <div class="menu-item" @click="$router.push('/guide/kuaishou')">
        <el-image style="width: 50px; height: 50px;padding-right: 8px;" :src="OSS+('/icon_kuaishou_1.png')"
        fit="fill"></el-image>
        <span style="padding-right: 8px;">快手订单打印设置</span>
    </div> -->
    <!-- 抖音加密代码 -->
    <div class="menu-item"  @click="openDonYin">
        <el-image style="width: 50px; height: 50px;padding-right: 8px;" :src="OSS+('/index_douyin_icon_0403.png')"
        fit="fill"></el-image>
        <span style="padding-right: 8px;">抖音订单打印设置</span>
    </div>
    <div class="menu-item" @click="showAutoPrint">
        <el-image style="width: 50px; height: 50px;padding-right: 8px;" :src="OSS+('/auto-print.png')"
        fit="fill"></el-image>
        <span style="padding-right: 8px;">自动打印</span>
    </div>
    <div class="menu-item" @click="showExpressDialog">
        <el-image style="width: 50px; height: 50px" :src="OSS+('/expressbill_setting.png')" fit="fill"></el-image>
        <span>快递单设置</span>
    </div>
    <div class="menu-item" @click="showFahuoDialog">
        <el-image style="width: 50px; height: 50px" :src="OSS+('/Invoice_setting.png')" fit="fill"></el-image>
        <span>发货单设置</span>
    </div>
    <div class="menu-item" @click="advancedSetting">
        <el-image style="width: 50px; height: 50px" :src="OSS+('/top_setting.png')" fit="fill"></el-image>
        <span>高级设置</span>
    </div>
    <!-- 发货单设置弹窗 -->
    <DeliverGoodsSettingDialog />

    <!-- 快递单设置弹窗 -->
    <ExpressSettingDialog />

    <!-- 高级设置弹窗 -->
    <AdvancedSettingDialog :settingData='this.settingData' />
    <!-- 自动打印 -->
    <AutoPrintSettingDialog />
 </div>
</template>

<script>

import ExpressSettingDialog from "@/components/allSettingsDialog/expressSettingDialog";
import DeliverGoodsSettingDialog from "@/components/allSettingsDialog/deliverGoodsSettingDialog";
import AdvancedSettingDialog from "@/components/allSettingsDialog/advancedSettingDialog";
import AutoPrintSettingDialog from "@/components/allSettingsDialog/autoPrintSettingDialog";
export default {
    components: {
        AutoPrintSettingDialog,//自动打印
        ExpressSettingDialog,//快递单设置弹窗
        DeliverGoodsSettingDialog,//发货单设置弹窗
        AdvancedSettingDialog,//高级设置弹窗
    },
    data() {
        return {
            // 高级设置
            settingData:{
            autoOrder:false,
            showOrder:false,
            settingPageValue:30,
            },
        }
    },
    methods: {
        openDonYin(){
            let routeUrl = this.$router.resolve({
            path: "/guide/douyin",
            });
            let href = window.location.origin + window.location.pathname + window.location.search + '#' + routeUrl.route.fullPath
            window.open(href , "_blank");
        },
        // 自动打印设置弹窗
        showAutoPrint(){
            this.$store.commit('batchprint/SAVE',{dialogAutoPrint:true})
        },
        // 发货全局设置弹窗
        showFahuoDialog(){
            this.$store.commit('batchprint/SAVE',{dialogFahuoVisible:true})
        },
        // 快递单设置弹窗
        showExpressDialog() {
            this.$store.commit('batchprint/SAVE',{dialogExpressVisible:true})
        },   
        // 高级设置弹窗
        advancedSetting() {
            this.$store.dispatch("setting/getConfig").then((res) => {
                if(res.code==0){
                    this.settingData.settingPageValue=res.data.config_info.pagesize
                    this.settingData.autoOrder=res.data.config_info.auto_merge==1?true:false
                    this.settingData.showOrder=res.data.config_info.priority_merge==1?true:false
                    this.$store.commit('batchprint/SAVE',{dialogAdvancedSettingVisible:true})
                }else {
                    this.$message({ message: res.message, type: "error" });
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.top-menu {
    font-size: 12px;
    position: absolute;
    top: 20px;
    right: 50px;
    display: flex;
    flex-direction: row;

    .menu-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5px;
        color: #c6bb9d;
        cursor: pointer;
    }
}

</style>