<template>
  <div>
    <!-- 高级设置弹窗 -->
   <div class="advanced-setting">
     <el-dialog
      :visible.sync="dialogAdvancedSettingVisible"
      @close='handleColse'
      width="454px">
      <div style="font-size:16px;color: #5D607A;padding-bottom:7px;padding-left:10px;font-weight:600;">高级设置</div>
      <div class="setting-content" style="font-size:15px;">
        <div style="color:#5D607A;font-weight: 600;">显示样式：</div>
        <div style="color:black;">
          <p style="margin-left:10px;"><el-checkbox v-model="settingData.autoOrder"></el-checkbox><span style="margin-left:14px;">自动合并订单</span></p>
          <p style="margin:12px 0px 9px 10px;"><el-checkbox v-model="settingData.showOrder"></el-checkbox><span style="margin-left:14px;">优先展示合单的订单或带有合单标识的订单</span></p>
          <p style="line-height: 30px;padding-left: 10px;">每页显示
             <el-select v-model="settingData.settingPageValue" style="width:60px; height:20px;margin-left:6px;">
              <el-option
                v-for="item in settingPageList"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
       </p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="float: right;padding-right: 20px;">
        <p class="heightSetting-confirm-btn"  @click="handleSettingConfirm">保存</p>
        <p class="heightSetting-cancel-btn" style='margin-left:14px;'  @click="handleSettingcancel">取消</p>
      </span>
    </el-dialog>
   </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props:['settingData'],
    data() {
        return {
        settingPageList:[30,50,100,150,200,500],
        }
    },
    computed:{
        dialogAdvancedSettingVisible:{
            get(){
                return this.$store.state.batchprint.dialogAdvancedSettingVisible
            },
            set(val) {
            this.$store.commit("batchprint/SAVE", {dialogAdvancedSettingVisible:val});
            }
        }
    },
    methods: {
    // 高级设置保存按钮
    handleSettingConfirm(){
       this.$store.commit('batchprint/SAVE',{dialogAdvancedSettingVisible:false})
       let params={
        auto_merge:this.settingData.autoOrder==true?1:0,
        priority_merge:this.settingData.showOrder==true?1:0,
        pagesize:this.settingData.settingPageValue,
        order_sort:this.$store.state.setting.config_info.order_sort
      }
       this.$store.dispatch("setting/editConfig", params).then((res) => {
        if (res.code == 0) {
          localStorage.setItem('autoMerge',params.auto_merge)
          localStorage.setItem('priorityMerge',params.priority_merge)
          localStorage.setItem('pageSize',params.pagesize)
          this.$message({ message: "保存成功", type: "success",duration:1000 });
           this.$store.commit("batchprint/SET_LISTLIMIT", this.settingData.settingPageValue+'');
          this.$store.dispatch("batchprint/get_search_result")
        } else {
          this.$message({ message: res.message, type: "error" });
        }
      });
    },
    //  高级设置取消按钮
    handleSettingcancel(){
        this.$store.commit('batchprint/SAVE',{dialogAdvancedSettingVisible:false})
    },
     handleColse(){
         this.$store.commit('batchprint/SAVE',{dialogAdvancedSettingVisible:false})
    }
    },
   
}
</script>

<style lang="less" scoped>
.advanced-setting /deep/ .el-dialog{
  background-color: #fff;
  .el-icon-close:before {
    content:''
  }
  .el-dialog__header {
    background-color: #FD7225;
    padding: 5px 20px 6px;
  }
 .el-dialog__body {
  padding: 10px 20px;
  padding-bottom: 0px;
 }
 .el-dialog__footer {
  padding: 0px 20px 7px !important;
 }
 
  .setting-content,.auto-print-content {
    display: flex;
    box-sizing: border-box;
    border-bottom: 1px solid #939191;
    border-top: 1px solid #939191;
    padding: 20px 8px;
    // height: 119px;
    // line-height: 45px;
    .el-input__inner {
      height: 25px;
      text-align: center;
    }
    .el-input__icon {
      line-height: 30px;
    }
    .el-checkbox__inner {
      border: 1px solid black;
    }
  }
  .heightSetting-confirm-btn,.heightSetting-cancel-btn {
     font-size: 15px;
      line-height: 15px;
      background-color: #FD7225;
      color: #fff;
      display: inline-block;
      border-radius: 3px;
      margin: 7px 12px 7px 0px;
      cursor: pointer;
     width:52px;
     height: 25px;
     line-height: 25px;
     text-align: center;
    // background-color: #FD7225;
  }
  .setting-confirm-btn,.setting-cancel-btn {
      font-size: 15px;
      line-height: 15px;
      background-color: #FD7225;
      color: #fff;
      display: inline-block;
      padding: 6px 15px;
      border-radius: 4px;
      margin: 10px;
      cursor: pointer;
    }
    .heightSetting-cancel-btn {
      background-color: #fff;
      color: #333;
      border: 1px solid #333;
    }
    .setting-cancel-btn {
      background-color: #fff;
      color: #333;
      border: 1px solid #333;
    }
  .auto-print-content {
    display: block;
    
  }
}

.content {
  position: relative;
  width: 100%;
  min-width: 1200px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  font-size: 12px;
  padding-bottom: 0px;
}
/deep/ .el-dialog .setting-content .el-input__inner {
  border-color:black;
  color:black;
  height: 20px !important;
}

/deep/ .el-dialog .setting-content .el-icon-arrow-up:before {
  color:black;
}
</style>