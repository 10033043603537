<style lang="less" src="./index.less" scoped></style>
<template src="./index.html"></template>

<script>
import { mapState } from "vuex";
import { lodopBatchPrint } from "@/utils/functions.js";
import { getPrinterList } from "@/utils/lodopPrint";
import provinceCity from "@/static/locationOption.json";
import myDialog1 from "@/UI/myDialog1";
import myDialog3 from "@/UI/myDialog3";
import reprintDialog1 from "@/components/dialog/reprintDialog1";
import reprintDialog3 from "@/components/dialog/reprintDialog3";
import { OSS } from "@/utils/PublicLink";

import selfPopup from "@/components/self_popup";
import ShipFromDialog from "@/components/selectDialog/ShipFromDialog";
import CourierSender from "@/components/selectDialog/CourierSender";


const redFlag = OSS + "/redFlag.png";
const ashFlag = OSS + "/ashFlag.png";
const yellowFlag = OSS + "/yellowFlag.png";
const greenFlag = OSS + "/greenFlag.png";
const blueFlag = OSS + "/blueFlag.png";
const purpleFlag = OSS + "/purpleFlag.png";


import GetFahuoErrorPopup from "@/UI/GetFahuoErrorPopup";
import appDialog from "@/components/appDialog";

export default {
  name: "ResultList",
  components: {
    selfPopup,
    myDialog1,
    myDialog3,
    reprintDialog1,
    reprintDialog3,
    GetFahuoErrorPopup,
    appDialog,
    ShipFromDialog,//请选择发货发件人弹窗
    CourierSender,//请选择快递发件人弹窗
  },
  data() {
    return {
      ShowEditRemarks: false,
      printMergeNumber: '',
      // 被选中的订单信息
      orderInfoList: [],
      orderOidList: "",
      falgRadio_temp: "",
      // 被选中的行
      selectRow: [],
      // 被选中的行数
      selectRowLength: 0,
      // 地址输入框控制参数
      addressEdit: [],
      loadingCity: false,
      loadingDistrict: false,
      provinces: [],
      cities: [],
      districts: [],
      // 被展开的行
      expandRow: [],
      // 备注弹窗变量
      dialogRemarkVisible: false,
      // 发货单发货人设置弹窗变量
      dialogFahuoConsignerVisible: false,

      // 快递单发货人设置弹窗变量
      expressConsigner: {},
      // 发货打印确认弹窗变量
      dialogFahuoVisible: false,
      // 发货打印确认弹窗绑定数据
      fahuoPrintOption: {
        printer: "",
        num: "1",
      },
      showAll: false,
      pageArr: ['30', '50', '100', '150', '200', '500'],
      dialogShow: false, // 复制成功的弹窗
      dialog2Show: false, // 未勾选点击打印发货单
      dialog3Show: false, // 未安装打印控件
      checkedGoods: [],
      getRowKeys(row) {
        return row.transaction_id
      },
      addressee: '', // 有退款时的收件人姓名
      dialogShow6: false, // 插件未连接或某种情况不好用
      dialogShow7: false, // 其他打印不了的问题
      dialogShow8: false, // 未勾选商品
      dialogShow9: false, // 挂起成功的弹窗
      dialogShow10: false, // 判断是否有打印过的发货单
      dialogShow12: false, // 批量修改备注
      dialogShow13: false, // 提示挂起弹窗
      is_hang_dialog_type: '', // 提示挂起弹窗类型
      tipsDialogShow: false, // 点击提示的弹窗
      messageText: '模板网点地址信息获取错误：抖音默认地址未设置',
      copyValue: '',
      copyValueAdress: '',
      nameVlaue: '',
      flagShow: false,
      listHeaderShow: false,
      listHeader: [],
      defaultType: true,
      dialogText: '请勾选打印对象',
      dialogText2: '',
      visibleChangeValue: false, // 下拉框的打开状态
      NaNpxarksTextarea: '',
      flagIndex: -1,
      flagImgShow: true,
      seller_memo_value: '',
      memoParams: {}, // 批量修改备注的传参

      GetFahuoError: false, // 发货错误
      print_component_error: false,
      selectMsg: '',
      addressData: {
        province: '',
        city: '',
        area: '',
        address: '',
        town: '',
        province_code: '',
        city_code: '',
        area_code: ''
      },
      showAdress: '',
      printShow: false,
      printShowText: '请选择同一个买家旺旺，2个以上订单进行合并',
      tableShow: true,
      showAsress: false,
      suborderId: '',
      mergeList: {},
      tidLlist: '',
      chaiBtn: false,
      has_express_print_state: false,
      blend_tips_popup: false,
      download_douyin_popup: false,
      download_kuaishou_popup: false,
    };
  },
  computed: {
    ...mapState({
      pageTotal: (state) => state.batchprint.pageTotal,
      pageHide: (state) => state.batchprint.pageHide,
      expressList: (state) => state.print.expressList,
      expressTemplateList: (state) => state.print.expressTemplateList,
      searchFormData: (state) => state.batchprint.searchFormData,
      resultLoading: (state) => state.batchprint.resultLoading,
      decryptInfo: (state) => state.singleprint.decryptInfo,
      fahuoConsignerList: (state) => state.print.fahuoConsignerList,
      tableHeader: (state) => state.print.tableHeader,
      tableHeader2: (state) => state.print.tableHeader2,
      // 打印参数
      printOrderInfo: (state) => state.batchprint.printOrderInfo,
      printExpressTemplate: (state) => state.batchprint.printExpressTemplate,
      printExpressBgimg: (state) => state.batchprint.printExpressBgimg,
      printFahuoTemplate: (state) => state.batchprint.printFahuoTemplate,
      printFahuoBgimg: (state) => state.batchprint.printFahuoBgimg,
      getPrintProgress: (state) => state.print.getPrintProgress,
    }),

    expressTemplate: {
      get() {
        return this.$store.state.batchprint.expressTemplate;
      },
      set(val) {
        this.$store.commit("batchprint/SET_EXPRESS_TEMPLATE", val);
      },
    },
    resultList: {
      get() {
        this.expandRow = []
        return this.$store.state.batchprint.resultList;
      },
    },
    decryptInfo: {
      get() {
        return this.$store.state.singleprint.decryptInfo;
      },
      set(val) {
        // 解密数据置空
        return val;
      }
    },
    pageDropdown() {
      return this.$store.state.batchprint.pageDropdown
    },

  },
  created() {
    this.listHeader = JSON.parse(JSON.stringify(this.tableHeader))
    let image_url = this.expressTemplateList[this.expressTemplate].image_url;
    let lodop_template = this.expressTemplateList[this.expressTemplate].lodop_template;
    this.$store.commit("batchprint/SET_PRINT_EXPRESS_TEMPLATE", lodop_template);
    this.$store.commit("batchprint/SET_PRINT_EXPRESS_BGIMG", image_url);
    this.provinces = provinceCity[0];
    for (var i = 0; i < 20; i++) {
      this.addressEdit[i] = false;
    }
    let data = {}
    if (this.resultList.data) {
      data['orderId'] = this.resultList.data[0].transaction_id;
      data['caid'] = this.resultList.data[0].caid;
    }
  },
  methods: {
    download_douyin_fn() {
      let routeUrl = this.$router.resolve({
        path: "/guide/douyin",
        });
        let href = window.location.origin + window.location.pathname + window.location.search + '#' + routeUrl.route.fullPath
        window.open(href , "_blank");
      // window.open(this.DownLoad_DouYin)
    },
    download_kuaishou_fn(){
      window.open("/#/guide/kuaishou")
    },
    // 复制成功时的回调函数
    onCopy(e) {
      this.dialogText2 = '成功复制到剪切板！'
      this.dialogShow = true
      setTimeout(() => {
        this.dialogShow = false
      }, 3000)

    },
    // 复制失败时的回调函数
    onError(e) {
      this.$message.error("复制失败！");
    },
    handleRadioChange(lable) {
      let image_url = this.expressTemplateList[lable].image_url;
      let lodop_template = this.expressTemplateList[lable].lodop_template;
      this.expressTemplate = lable
      this.$store.commit(
        "batchprint/SET_PRINT_EXPRESS_TEMPLATE",
        lodop_template
      );
      this.$store.commit("batchprint/SET_PRINT_EXPRESS_BGIMG", image_url);
    },

    // resulttable函数
    // 处理全选表格
    handleALL(val) {
      val = val.filter((item, index) => {
        if (item.is_hang == 1) {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(item, false);
          });
          return false
        } else {
          return true
        }
      })
      this.handleSelect(val);

    },
    // 处理多个单选表格
    handleSelect(row) {
      this.selectRow = [];
      this.orderInfoList = [];
      if (row.length > 0) {
        row.forEach((value, index) => {
          this.selectRow.push(value.oid);
          this.orderInfoList.push(value);
        });
      }
      this.selectRowLength = row.length;
    },

    // 当选择项发生变化时触发
    handelSelectChange(val) {
      this.printMergeNumber = 0
      for (let i in val) {
        if (val[i].is_merge_order == 1) {
          this.printMergeNumber = 1
        }
      }
      this.handleSelect(val);
    },
    create_copy_data(row) {
      this.copyValue = `
        旺旺：${row.buyer_login_id || ""}
        收件人：${row.to_full_name || ""}
        手机：${row.to_mobile || ""}
        固话：${row.to_phone || ""}
        邮编：${row.to_division_code || ""}
        收件地址：${row.province +
        " " +
        row.city +
        " " +
        row.area +
        " " +
        row.town +
        " " +
        row.address
        }
      `,
        this.copyValueAdress = row.province + " " + row.city + " " + row.area + " " + row.town + " " + row.address
    },
    // 处理表格某一行展开
    handleExpand(row, expandedRows) {
      if (row.merge_order) {
        for (let j in row.merge_order) {
          row.merge_order[j].editRemarks = false
          row.merge_order[j].falgRadio_temp = ''
          row.merge_order[j].seller_memo_value = ''
        }
      }
      this.flagShow = false
      this.create_copy_data(row)

      if (expandedRows.length) {
        this.expandRow = []
        if (row) {
          this.expandRow.push(row.transaction_id)// 每次push进去的是每行的ID
        }
      } else {
        this.expandRow = []
      }

      for (let i in this.resultList.data) {
        let data = this.resultList.data[i]
        this.resultList.data[i].falgRadio = data.seller_remark_icon
        this.resultList.data[i].seller_memo_value = data.seller_memo_value
        if (this.resultList.data[i].is_merge_order == 1) {
          for (let k in this.resultList.data[i].merge_order) {
            switch (this.resultList.data[i].merge_order[k].seller_remark_icon) {
              case '1':
                this.resultList.data[i].merge_order[k].flagValue = redFlag
                break;
              case '2':
                this.resultList.data[i].merge_order[k].flagValue = blueFlag
                break;
              case '3':
                this.resultList.data[i].merge_order[k].flagValue = greenFlag
                break;
              case '4':
                this.resultList.data[i].merge_order[k].flagValue = yellowFlag
                break;
            }
          }
        }
        switch (data.seller_remark_icon) {
          // case '0':
          //   this.resultList.data[i].flagValue = ashFlag
          //   break;
          case '1':
            this.resultList.data[i].flagValue = redFlag
            break;
          case '2':
            this.resultList.data[i].flagValue = blueFlag
            break;
          case '3':
            this.resultList.data[i].flagValue = greenFlag
            break;
          case '4':
            this.resultList.data[i].flagValue = yellowFlag
            break;
        }
      }
    },
    // 给表格某一行绑定类名
    tableRowClassName({ row, rowIndex }) {
      let className = "";
      if (row.is_hang == 1) {
        className = 'lockClass'
      }
      for (let item of this.selectRow.values()) {
        if (item == row.transaction_id) {
          className = "selected-bgcolor";
        }
      }
      for (let item of this.expandRow.values()) {
        if (item == row.transaction_id) {
          className = "expanded-bgcolor";
        }
      }
      return className;
    },
    provinceChanged(code, index) {
      if (code != "") {
        var str = "0," + code;
        this.loadingCity = true;
        this.cities = provinceCity[str];
        this.resultList.data[index].province_code = code;
        this.resultList.data[index].province = provinceCity[0][code];
        this.loadingCity = false;
        this.nullcity(index);
        this.nulldistrict(index);
      }
    },
    cityChanged(code, index) {
      if (code != "") {
        var str = "0," + this.resultList.data[index].province_code + "," + code;
        this.loadingDistrict = true;
        this.districts = provinceCity[str];
        this.resultList.data[index].city_code = code;
        this.resultList.data[index].city = this.cities[code];
        this.loadingDistrict = false;
        this.nulldistrict(index);
      }
    },
    cityLoad(index) {
      if (this.resultList.data[index].province_code == "") {
        Object.keys(this.provinces).forEach((key) => {
          if (this.provinces[key] == this.resultList.data[index].province) {
            this.resultList.data[index].province_code = key;
          }
        });
      }
      var str = "0," + this.resultList.data[index].province_code;
      this.cities = provinceCity[str];
    },
    districtChanged(code, index) {
      if (code != "") {
        var str =
          "0," +
          this.resultList.data[index].province_code +
          "," +
          this.resultList.data[index].city_code;
        this.resultList.data[index].area = provinceCity[str][code];
        this.resultList.data[index].area_code = code;
        // this.resultList.post_code=code;
      }
    },
    districtLoad(index) {
      if (this.resultList.data[index].city_code == "") {
        Object.keys(this.provinces).forEach((key) => {
          if (this.provinces[key] == this.resultList.data[index].province) {
            var str = "0," + key;
            this.cities = provinceCity[str];
            Object.keys(this.cities).forEach((key2) => {
              if (this.cities[key2] == this.resultList.data[index].city) {
                this.resultList.data[index].city_code = key2;
              }
            });
          }
        });
      }
      var str =
        "0," +
        this.resultList.data[index].province_code +
        "," +
        this.resultList.data[index].city_code;
      this.districts = provinceCity[str];
    },
    nullcity(index) {
      this.resultList.data[index].city_code = "";
      this.resultList.data[index].city = "";
    },
    nulldistrict(index) {
      this.resultList.data[index].area = "";
      this.resultList.data[index].area_code = "";
    },
    updateAddress(index) {
      let params = {
        transaction_id: this.resultList.data[index].transaction_id,
        province: this.resultList.data[index].province,
        city: this.resultList.data[index].city,
        area: this.resultList.data[index].area,
        areaCode: this.resultList.data[index].area_code,
        town: this.resultList.data[index].town,
        address: this.resultList.data[index].address,
        create_time: this.resultList.data[index].create_time,
      }
      this.$store
        .dispatch("print/update_order_address", params)
        .then((res) => {
          if (res.code == 0) {
            this.create_copy_data(this.resultList.data[index])
            this.$message.success(res.message);
            this.$set(this.addressEdit, index, false);
            this.resultList.data[index].to_area = params.province + params.city + params.area + params.town + params.address;
          } else {
            this.$message.error(res.message);
          }
        });
    },
    hideAddressSelect(index, val) {
      val.province = this.addressData.province
      val.city = this.addressData.city
      val.area = this.addressData.area
      val.address = this.addressData.address
      val.town = this.addressData.town
      this.resultList.data[index].province_code = this.addressData.province_code
      this.resultList.data[index].city_code = this.addressData.city_code
      this.resultList.data[index].area_code = this.addressData.area_code
      this.$set(this.addressEdit, index, false);
    },
    showAddressSelect(index, val) {
      this.showAdress = index
      this.addressData = {
        province: val.province,
        city: val.city,
        area: val.area,
        address: val.address,
        town: val.town,
        province_code: this.resultList.data[index].province_code,
        city_code: this.resultList.data[index].city_code,
        area_code: this.resultList.data[index].area_code,
      }
      this.$set(this.addressEdit, index, true);
    },
    // RemarkDialog函数
    // 点击加号按钮显示弹窗
    showRemarkDialog(oid, remark) {
      this.$store.commit("batchprint/SET_REMARK_ORDERID", oid);
      this.$store.commit("batchprint/SET_REMARK_MEMO", remark);
      this.dialogRemarkVisible = true;
    },
    // 更新弹窗是否隐藏函数
    updateRemarkVisible(val) {
      this.dialogRemarkVisible = val;
    },
    // 点击取消按钮隐藏弹窗
    hideRemarkDialog() {
      this.dialogRemarkVisible = false;
    },
    // 点击保存按钮保存数据隐藏弹窗
    saveRemarkDialog() {
      this.$store.dispatch("batchprint/add_order_remark");
      this.dialogRemarkVisible = false;
    },
    // 点击打印发货单按钮
    async handleClickFahuoPrint() {
      try {
        let lodop_printer = await getPrinterList();
        // 挂起订单提示
        for (let i in this.orderInfoList) {
          if (this.orderInfoList[i]["is_hang"] == 1) {
            this.dialogShow13 = true
            this.is_hang_dialog_type = "打印发货单"
            return false
          }
        }

        if (parseInt(this.selectRowLength) == 0) {
          // this.$message.error("请先选择要打印的订单");
          this.dialog2Show = true
          this.dialogText = '请勾选打印对象'
        } else {
          let checkedType = []
          this.resultList.data.forEach((value) => {
            if (value.checkedGoods) {
              checkedType.push(Object.keys(value.checkedGoods).length)
              if (Object.keys(value.checkedGoods).length < 1) {
                this.nameVlaue = value.buyer_login_id
              }
            } else {
              checkedType.push(0)
            }

          })
          // if(checkedType.indexOf(0) !== -1) {
          //   this.dialogShow8 = true
          // }else {
          let arr = []
          this.orderInfoList.forEach((value) => {
            if (value.invoice_print_state == '1') {
              arr.push(1)
            }
          })
          if (arr.length > 0) {
            this.dialogShow10 = true
          } else {
            this.$store.commit("batchprint/SAVE", { dialogFahuoConsignerVisible: true });
          }

          // }
        }
      } catch (error) {
        this.print_component_error = true
      }
    },
    // 发货弹窗函数
    updateFahuoVisible(val) {
      this.dialogFahuoVisible = val;
    },
    // 点击打印快递单按钮
    handleClickExpressPrint() {
      if(this.getPrintProgress.status=='execute'){
        return false
      }
      if (window.print_component_error || !window.cainiao_print_list) {
        this.print_component_error = true
        return false;
      }

      if (parseInt(this.selectRowLength) == 0) {
        // this.$message.error("请先选择要打印的订单");
        this.dialog2Show = true
        this.dialogText = '请勾选打印对象'
      } else {
        // 挂起订单提示、混合打单提醒、抖音订单未安装打印控件
        let outcode = this.orderInfoList[0]['outcode']
        for (let i in this.orderInfoList) {
          // 存在混合打印
          if (outcode != this.orderInfoList[i]["outcode"]) {
            console.log(outcode, this.orderInfoList[i]["outcode"], this.orderInfoList[i], '---混合打印')
            this.blend_tips_popup = true
            return false
          }
          // 存在挂起订单
          if (this.orderInfoList[i]["is_hang"] == 1) {
            this.dialogShow13 = true
            this.is_hang_dialog_type = "打印快递单"
            return false
          }
          // 抖音订单未安装打印控件
          if (this.orderInfoList[i]["outcode"] == 'douyin' && !window.douyin_print_list) {
            this.download_douyin_popup = true
            return false
          }
          // 快手订单未安装打印控件
          if (this.orderInfoList[i]["outcode"] == 'kuaishou' && !window.kuaishou_print_list) {
            this.download_kuaishou_popup = true
            return false
          }
        }
        // 标记是否有已打印订单
        this.has_express_print_state = false
        this.orderInfoList.map(item => {
          if (item.express_print_state == 1) {
            this.has_express_print_state = true;
          }
        })

        this.$store.commit('batchprint/SAVE', { dialogExpressConsignerVisible: true })
      }
    },
    // 发货
    handleClickDeliverGoods() {
      for (let i in this.orderInfoList) {
        if (this.orderInfoList[i]["is_hang"] == 1) {
          this.dialogShow13 = true
          this.is_hang_dialog_type = "发货"
          return false
        }
      }
      if (parseInt(this.selectRowLength) == 0) {
        // this.$message.error("请先选择要打印的订单");
        this.dialog2Show = true
        this.dialogText = '请勾选发货对象'
        return false
      }
      let mergeNo = []//合单
      for (let item of this.orderInfoList) {
        if (item.merge_order) {
          for (let k in item.merge_order) {
            let arr = {}
            arr = item.merge_order[k]
            mergeNo.push(arr)
          }
        } else {
          let orderNo = {}
          orderNo = item
          mergeNo.push(orderNo)
        }
      }
      // 查看选择的订单中是够有能拆分的单子
      let message = false
      for (let i in this.orderInfoList) {
        if (this.orderInfoList[i].is_merge_order == 1 && this.orderInfoList[i].merge_no == '') {
          message = true
          break;
        } else {
          message = false
        }
      }
      if (message) {
        this.$message.error('有合单未打印过快递单，请先打印快递单，再进行发货操作')
        return false
      } else {
        this.GetFahuoError = false
        this.$store.dispatch("batchprint/OpDeliverySendOrder", { data: mergeNo }).then(res => {
          if (res.message != "成功") {
            this.GetFahuoError = res.message
          } else {
            this.$message.success("发货成功")
          }
          this.$store.dispatch("batchprint/get_search_result")
        })
      }

    },
    // 换页函数
    handleCurrentChange(page) {
      this.$store.commit("batchprint/SET_PAGENUM", page);
      this.$store.dispatch("batchprint/get_search_result");
      for (var i = 0; i < 20; i++) {
        this.addressEdit[i] = false;
      }
      this.selectRow = [];
      this.expandRow = [];
      document.documentElement.scrollTop = 0
    },
    // doPrintFahuo() {
    //   if (this.printOrderInfo.length == 0) {
    //     this.$message.error("请选择要打印的订单");
    //   } else {
    //     let orderInfoList = this.orderInfoList.filter(item=>{
    //       return item.is_hang != 1
    //     })
    //     this.dialogFahuoVisible = false
    //     // let a=deliverGoodsParams.deliver_goods(1,this.orderInfoList)
    //       let mergeNo=[]//合单
    //       let orderNo=''//不合单
    //          for (let item of this.orderInfoList) {
    //           let tid=[]
    //           if(item.merge_order && item.merge_no==''){
    //             let tParamId=""
    //             for(let k in item.merge_order){
    //               tid+=item.merge_order[k].transaction_id+ "|" + item.merge_order[k].create_time+ ","
    //             }
    //             tid=tid.substring(0,tid.lastIndexOf(","));
    //             tParamId+=tid+','
    //             tParamId=tParamId.substring(0,tParamId.lastIndexOf(","));
    //             mergeNo.push(tParamId)
    //           }else if(item.merge_order && item.merge_no){
    //              mergeNo.push(item.merge_no)
    //           }else{
    //              orderNo+=item.transaction_id + "|" + item.create_time + ","
    //           }
    //       }
    //       orderNo=orderNo.substring(0,orderNo.lastIndexOf(","));
    //       let params={merge_no:mergeNo,order_no:orderNo}
    //       this.$store
    //         .dispatch("batchprint/update_fahuo_state",{transaction_id:params})
    //         .then((res) => {
    //           if(res.code==20001){
    //             this.$message.error(res.message)
    //             return false
    //           }else{
    //              lodopBatchPrint(
    //               this.LODOP,
    //               "2",
    //               '',
    //               1,
    //               orderInfoList,
    //               this.fahuoConsigner,
    //               this.printFahuoBgimg,
    //               this.printFahuoTemplate
    //             )
    //           this.orderOidList = "";
    //           this.$store.dispatch("batchprint/get_search_result")
    //           }
    //         });
    //     // });
    //   }
    // },
    // 数据加密切换
    async showInfo(showAll, index) {
      this.showAll = !showAll;
      let data = {}
      data['orderId'] = this.resultList.data[index].transaction_id;
      data['caid'] = this.resultList.data[index].caid;
      if (this.showAll) {
        // await this.$store.dispatch('singleprint/decryptBuyer',data).then((res)=>{});
      }
    },
    offShow() {
      if (this.showAll) {
        this.showAll = false;
        this.decryptInfo.contactPerson = '';
        this.decryptInfo.mobile = '';
        this.decryptInfo.address = '';
        this.decryptInfo.city = '';
        this.decryptInfo.province = '';
        this.decryptInfo.town = '';
      }
    },
    // 合并
    handleMerge(val, row) {
      this.showAsress = false
      if (val == true) {
        row.mergeCheck = 1
        this.showAsress = true
      } else {
        this.showAsress = true
        row.mergeCheck = 0
      }
    },
    // 找主单
    find_main_order(sub_order) {
      for (let i in this.mergeList) {
        if (sub_order.main_order_id == i) {
          this.mergeList[i].transaction_id = Math.round(Math.random() * Math.pow(10, 15))
          return this.mergeList[i]
        }
      }
      return false
    },

    // 重新排序将未合单的子单放在主单下面
    order_sort() {
      let sub_Arr = new Object()
      let order = JSON.parse(JSON.stringify(this.resultList.data))
      order = order.filter((item, index) => { // 找到所有拆单
        item.old_order_index = index
        if (item.main_order_id) {
          if (Array.isArray(sub_Arr[item.main_order_id])) {
            sub_Arr[item.main_order_id].push(item)
          } else {
            sub_Arr[item.main_order_id] = [item]
          }
          return false
        } else {
          return true
        }
      })
      for (let s in sub_Arr) { // 找对应的主单
        let o_index = false
        for (let o in order) {
          if (s == order[o].yuan_id) { // 找到第一个主单
            o_index = parseInt(o)
          }
        }
        if (o_index !== false) { // 如果查询到主单，把所有子单插入到主单后面
          sub_Arr[s].map(item => {
            order.splice(parseInt(o_index) + 1, 0, item)
          })
        } else {
          sub_Arr[s].map(item => {
            order.splice(item.old_order_index, 0, item)
          })
        }
      }
      this.resultList.data = order
      return this.resultList.data
    },
    // 按此地址合并
    handleAdressMerge(ind) {
      let isSameOrder = []
      let orderId = ''
      let payTime = []
      let sum_item_amount = 0
      let quantity = 0
      let delivery_limit = false
      // 把选中的放进新数组
      let newArr = this.resultList.data.filter((item) => {
        if (item.mergeCheck == 1) {
          quantity += item.quantity
          orderId += item.transaction_id + ',',
            sum_item_amount += parseFloat(item.item_amount)
          payTime.push(item)
          isSameOrder.push(item.heNum)
          if (item.delivery_limit == '已超时') {
            delivery_limit = true
          }
          return item
        }
      })
      // 下单时间排序
      let dateSort = payTime.sort(function (a, b) {
        return Date.parse(a.create_time) - Date.parse(b.create_time)
      })

      // 剩余发货时间排序
      let creatTime = ''
      let time = []
      for (let j in payTime) {
        if (payTime[j].delivery_limit != '已超时') {
          // 如果没有天数，就补0
          if (payTime[j].delivery_limit.indexOf('天') == -1) {
            creatTime = '00天' + payTime[j].delivery_limit
          } else {
            creatTime = payTime[j].delivery_limit
          }
          //  格式化时间
          let createDate = creatTime.replace('天', ':').replace('小时', ':').replace('分', '')
          // 转换为时间戳
          let dateTime = new Date('2022-08-11 ' + createDate).getTime()
          let data = { createDate: dateTime, time: payTime[j].delivery_limit }
          time.push(data)
        }
      }
      let timeSort = time.sort(function (a, b) {
        return parseInt(a.createDate) - parseInt(b.createDate)
      })

      // 选中小于2弹出弹框
      if (newArr.length < 2) {
        this.printShow = true
        return false
        //如果选中的不是同一个单子
      } else if (this.isAllEqual(isSameOrder) == false) {
        this.printShow = true
        return false
      } else {
        // 最早的一个合单子单位置
        let firstIndex = 0
        for (let i in this.resultList.data) {
          if (this.resultList.data[i].mergeCheck == '1') {
            firstIndex = i
            break;
          }
        }
        // 找到原主单
        let main_order = this.find_main_order(newArr[0])
        if (!main_order) {
          return false
        }
        // 合并之后表格未展开显示数据
        main_order.tid_list = orderId.substring(0, orderId.lastIndexOf(','))
        main_order.quantity = quantity
        main_order.seller_memo = dateSort[0].seller_memo
        main_order.buyer_feedback = dateSort[0].buyer_feedback
        main_order.max_pay_time = dateSort[0].pay_time
        main_order.max_create_time = dateSort[0].create_time
        main_order.sum_item_amount = sum_item_amount
        main_order.delivery_limit = delivery_limit ? "已超时" : timeSort[0].time
        // newArr
        main_order.merge_order = newArr
        this.resultList.data.splice(firstIndex, 0, main_order)
        let Arr = this.resultList.data.filter((ite, index) => {
          if (!ite.mergeCheck) {
            return ite
          }
        })
        this.resultList.data = Arr
        this.order_sort();
      }
    },
    // 按子单拆分
    suborderSplit(id, row) {
      this.tidLlist = row.tid_list
      this.mergeList[row.transaction_id] = row
      this.suborderId = id
      this.tableShow = false
      for (let i in row.merge_order.reverse()) {
        row.merge_order[i].merge = 1
        row.merge_order[i].mergeCheck = 0
        row.merge_order[i].main_order_id = row.yuan_id
        this.resultList.data.splice(id + 1, 0, row.merge_order[i])
      }
      this.resultList.data.splice(id, 1)
      this.tableShow = true
      this.$refs.multipleTable.clearSelection();
    },
    //判断选择的单子是否是1个单子
    isAllEqual(array) {
      if (array.length > 0) {
        return !array.some(function (value, index) {
          return value !== array[0]
        })
      } else {
        return true
      }
    },
    handleSizeChange(value) {
      this.$store.commit("batchprint/SAVE", { pageDropdown: value })
      let params = {
        pagesize: value,
        auto_merge: localStorage.getItem('autoMerge'),
        priority_merge: localStorage.getItem('priorityMerge'),
        order_sort: this.$store.state.setting.config_info.order_sort
      }
      this.$store.dispatch("setting/editConfig", params)
      // this.pageDropdown = value+'条/页'
      this.$store.commit("batchprint/SET_PAGENUM", 1);
      localStorage.setItem('pageSize', value)
      // this.$store.commit("batchprint/SET_LISTLIMIT", value);
      this.$store.dispatch("batchprint/get_search_result");
      for (var i = 0; i < 20; i++) {
        this.addressEdit[i] = false;
      }
      this.selectRow = [];
      this.expandRow = [];
      this.$forceUpdate()
      document.documentElement.scrollTop = 0
    },
    handleCheckedGoodsChange(index, value) {
    },
    comfirBtn() {
      window.location.href = '/打印控件.zip'
    },
    refreshBtn() {
      location.reload();
    },
    reduceClick() {
      this.$parent.ExpressGlobalSettings();
    },
    addClick() {
      this.$parent.openAddExpressVisible();
    },
    infoBtn() {
      // this.searchFormData.startTime = [this.getBeginTime(), this.getEndTime()];
      this.searchFormData.Time = '1';
      this.searchFormData.status = '';
      this.searchFormData.print_state = '';
      this.searchFormData.ww_baby_count_money = '';
      this.searchFormData.delivery_limit_type = '';
      this.searchFormData.refund_state = '';
      this.searchFormData.remark_type = '';
      this.searchFormData.wbcm_value = '';
      this.searchFormData.limit = localStorage.getItem('pageSize');
      this.$store.commit("batchprint/SAVE", { is_shipped: 1 })
      this.$store.dispatch("batchprint/get_search_result")
      this.$store.commit("batchprint/SET_ISSHOW", "ResultList");
      this.$store.commit("batchprint/SET_DATATYPE", false);
    },
    listHeaderShowBtn() {
      this.listHeaderShow = !this.listHeaderShow
    },
    listConfirmBtn(params) {
      let field_data = JSON.stringify(this.listHeader)
      if (params == "restore_defaults") {
        field_data = "restore_defaults"
      }
      this.$confirm(field_data == "restore_defaults" ? '确认恢复默认配置？' : '确认保存列配置？')
        .then(_ => {
          this.$store.dispatch("print/setFieldData", { field_data }).then((res) => {
            this.$store.commit("print/SET_TABLE_HEADER", JSON.parse(res.data.data));
            this.listHeader = JSON.parse(res.data.data)
            this.$message.success('配置成功')
            this.listHeaderShow = false
          })
        })
    },
    listNoneBtn(num) {
      this.listHeaderShow = false
      this.listHeader = JSON.parse(JSON.stringify(this.tableHeader))
    },
    sortChange(num, index) {
      let obj = this.listHeader[index]
      if (obj.is_sort == 0) {
        return false
      }
      if (num == 0) {
        if (index !== 0) {
          this.listHeader[index] = this.listHeader.splice(index - 1, 1, obj)
          this.listHeader[index] = this.listHeader[index][0]
        }
      } else {
        if (index !== this.listHeader.length - 1) {
          this.listHeader[index] = this.listHeader.splice(index + 1, 1, this.listHeader[index])
          this.listHeader[index] = this.listHeader[index][0]
        }
      }
    },
    // 更新挂起状态
    UpdateHangFN(status) {
      let params = {
        is_hang: status,
        create_time: [],
        transaction_id: [],
      }
      for (let item of this.orderInfoList) {
        if (item.merge_order) {
          for (let k in item.merge_order) {
            let tId = item.merge_order[k].transaction_id
            let time = item.merge_order[k].create_time
            params.transaction_id.push(tId)
            params.create_time.push(time)
          }
        } else {
          params.transaction_id.push(item.transaction_id)
          params.create_time.push(item.create_time)

        }
      }
      this.$store.dispatch("batchprint/UpdateHang", params).then(res => {
        if (res.code == 0) {
          this.dialogText2 = status == 1 ? '所选订单挂起成功！' : '所选订单取消挂起成功！'
          this.dialogShow = true
          setTimeout(() => {
            this.dialogShow = false
          }, 3000)
          this.$store.dispatch("batchprint/get_search_result")
        }
      });
    },
    chenckItemHandle(command) {
      if (command == '挂起') {
        if (parseInt(this.selectRowLength) == 0) {
          this.dialog2Show = true
          this.dialogText = '请勾选挂起对象'
        } else {
          this.UpdateHangFN(1)
        }
      } else if (command == '取消挂起') {
        if (parseInt(this.selectRowLength) == 0) {
          this.dialog2Show = true
          this.dialogText = '请勾选取消挂起对象'
        } else {
          this.UpdateHangFN(2)
        }
      } else if (command == '批量备注') {
        if (parseInt(this.selectRowLength) == 0) {
          this.dialog2Show = true
          this.dialogText = '请勾选批量备注对象'
        } else {
          this.NaNpxarksTextarea = ''
          this.flagIndex = -1
          let orderId = []
          let create_time = []
          for (let item of this.orderInfoList) {
            if (item.merge_order) {
              for (let k in item.merge_order) {
                let tId = item.merge_order[k].transaction_id
                let time = item.merge_order[k].create_time
                orderId.push(tId)
                create_time.push(time)
              }
            } else {
              orderId.push(item.transaction_id)
              create_time.push(item.create_time)
            }
          }
          let memoParams = {
            orderId: orderId,
            create_time: create_time,
          }

          this.memoParams = memoParams
          this.dialogShow12 = true
        }
      }

    },

    saveRemarkValue() {
      if (this.flagIndex == '-1') {
        this.$message.error('请选择旗子')
      } else {
        this.memoParams.memo = this.NaNpxarksTextarea,
          this.memoParams.remarkIcon = this.flagIndex,
          this.$store
            .dispatch("singleprint/OrderMemoAdd", this.memoParams)
            .then((res) => {
              this.dialogShow12 = false
              this.dialogText2 = '所选订单批量备注成功！'
              this.$store.dispatch("batchprint/get_search_result");
              this.$store.commit("batchprint/SET_ISSHOW", "ResultList");
              this.$store.commit("batchprint/SET_DATATYPE", false);
              this.dialogShow = true
              setTimeout(() => {
                this.dialogShow = false
              }, 3000)
            });
      }



    }, showFunction(value) {
      for (let i in this.tableHeader) {
        if (this.tableHeader[i].lable == value) {
          return this.tableHeader[i].show
        }
      }
    },
    visibleChange(value) {
      this.visibleChangeValue = value
    },

    changeFlag(value) {
      this.flagImgShow = false
      // if(value == '0') this.resultList.data[index].flagValue = ashFlag
      this.falgRadio_temp = value;
      setTimeout(() => {
        this.flagImgShow = true
      }, 0)
    },
    saveMemo(list, index) {
      if (this.falgRadio_temp) {
        let params = {
          orderId: [list.transaction_id],
          remarkIcon: this.falgRadio_temp,
          memo: this.seller_memo_value,
          create_time: [list.create_time]
        }
        this.$store
          .dispatch("singleprint/OrderMemoAdd", params)
          .then((res) => {
            this.$message.success(res.message);
            if (this.falgRadio_temp == '1') this.resultList.data[index].flagValue = redFlag
            if (this.falgRadio_temp == '2') this.resultList.data[index].flagValue = blueFlag
            if (this.falgRadio_temp == '3') this.resultList.data[index].flagValue = greenFlag
            if (this.falgRadio_temp == '4') this.resultList.data[index].flagValue = yellowFlag
            this.falgRadio_temp = ""
            this.resultList.data[index].seller_memo = this.seller_memo_value
            this.flagShow = false
          });
      } else {
        this.$message.error("请选择旗子");
      }
    },
    changeMemo(seller_memo) {
      this.flagShow = true
      this.seller_memo_value = seller_memo
    },
    // 合单修改备注插旗
    changeMemoHe(seller_memo, ind, merge_order) {
      this.ShowEditRemarks = false
      merge_order[ind].editRemarks = true
      this.seller_memo_value = seller_memo
      for (let i in merge_order) {
        merge_order[i].editRemarks = false
        if (ind == i) {
          merge_order[i].editRemarks = true
        }
      }
      this.ShowEditRemarks = true
    },
    changeFlagHe(value, ite) {
      this.flagImgShow = false
      ite.falgRadio_temp = value;
      setTimeout(() => {
        this.flagImgShow = true
      }, 0)
    },
    // 合单备注修改关闭按钮
    closeMemo(val) {
      this.ShowEditRemarks = false
      val.editRemarks = false
      this.ShowEditRemarks = true
    },
    // 合单备注修改保存按钮
    saveMemoHe(list, index, rowInd) {
      if (list[index].falgRadio_temp) {
        let params = {
          orderId: [list[index].transaction_id],
          remarkIcon: list[index].falgRadio_temp,
          memo: this.seller_memo_value,
          create_time: [list[index].create_time]
        }
        this.$store
          .dispatch("singleprint/OrderMemoAdd", params)
          .then((res) => {
            this.$message.success(res.message);
            if (list[index].falgRadio_temp == '1') this.resultList.data[rowInd].merge_order[index].flagValue = redFlag
            if (list[index].falgRadio_temp == '2') this.resultList.data[rowInd].merge_order[index].flagValue = blueFlag
            if (list[index].falgRadio_temp == '3') this.resultList.data[rowInd].merge_order[index].flagValue = greenFlag
            if (list[index].falgRadio_temp == '4') this.resultList.data[rowInd].merge_order[index].flagValue = yellowFlag
            this.falgRadio_temp = ""
            this.resultList.data[rowInd].merge_order[index].seller_memo = this.seller_memo_value
            this.resultList.data[rowInd].seller_memo = this.seller_memo_value
            list[index].editRemarks = false
            list[index].flageShow = false
            this.ShowEditRemarks = false
            setTimeout(() => {
              this.ShowEditRemarks = false
            }, 0);
          });
      } else {
        this.$message.error("请选择旗子");
      }
    }
  },
  updated() {
    if (this.searchFormData.status == 0) {
      this.selectMsg = '全部订单'
    }
    if (this.searchFormData.status == 1) {
      this.selectMsg = '等待买家付款'
    }
    if (this.searchFormData.status == 2) {
      this.selectMsg = '买家已付款'
    }
    if (this.searchFormData.status == 3) {
      this.selectMsg = '卖家已发货'
    }
    if (this.searchFormData.status == 4) {
      this.selectMsg = '交易成功'
    }
    this.$set(this.addressEdit, this.showAdress, false);
  },
};
</script>

