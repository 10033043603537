<template>
  <div class="advanced-setting">
    <!-- 自动打印弹窗 -->
     <el-dialog
      :visible.sync="dialogAutoPrint"
      @open='showAutoPrint'
      width="476px">
      <div style="font-size:18px;color:#5D607a;padding-bottom:10px;padding-left:15px; font-weight:600;">自动打印</div>
      <div class="auto-print-content" style="line-height:32px;height:210px;">
        <div style="font-size:16px;color: black;font-weight:600;">选择打印方式：</div>
        <div style="display:flex;padding-left: 36px;">
          <p><el-radio v-model="autoPrintRadio" label="0" >手动打印</el-radio></p>
          <p style="padding-left:30px;"><el-radio v-model="autoPrintRadio" label="1">自动打印</el-radio></p>
        </div>
        
        <div v-if="this.autoPrintRadio==1" style="background-color:rgb(240,240,240);padding:10px 36px;border-radius: 10px;margin-top: 10px;padding-bottom: 25px;width: 380px;box-sizing: border-box;">
          <p><el-radio v-model="newOrderPrint" label="0">有新订单后立即打印</el-radio></p>
          <div><el-radio style='margin-right: 15px' v-model="newOrderPrint" label="1">有新订单后，稍后打印</el-radio>
             <el-select v-model="autoPrintPageValue" style="width:90px; height:20px;">
              <el-option
                v-for="item in autoPrintPageList"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
       </div>
        </div>
      </div>
      <div class="dialog-footer" style="float: right;padding-right: 20px;margin-bottom: 10px;">
        <p class="setting-confirm-btn" style='margin-top:8px;' @click="handleAutoPrintConfirm">保存</p>
        <p class="setting-cancel-btn" style='margin-top:8px;margin-left:15px;' @click="handleAutoPrintCancel">取消</p>
      </div>
    </el-dialog>
   </div>
</template>

<script>
export default {
  data() {
    return {
      // 自动打印 
      autoPrintRadio:'0',
      newOrderPrint:'0',
      cetatAutoPrintRadio:'',
      cetatnewOrderPrint:'',
      cetatautoPrintPageValue:'',
      autoPrintPageList:[30+'分钟',1+'小时',3+'小时',6+'小时',12+'小时'],
      autoPrintPageValue:'30分钟',    
    }
  },
  computed:{
    dialogAutoPrint:{
      get(){
        return this.$store.state.batchprint.dialogAutoPrint
      },
      set(val){
        return  this.$store.commit('batchprint/SAVE',{dialogAutoPrint:val})
      }
    }
  },
  methods: {
    showAutoPrint(){
      this.$store.dispatch("setting/getConfig").then((res) => {
      this.autoPrintRadio=res.data.config_info.auto_print+''
      this.newOrderPrintVal=res.data.config_info.auto_print_delay
      if(this.newOrderPrintVal!=0){
        this.newOrderPrint='1'
      if(this.newOrderPrintVal==30){
        this.autoPrintPageValue='30分钟'
      }
      if(this.newOrderPrintVal==60){
        this.autoPrintPageValue='1小时'
      }
      if(this.newOrderPrintVal==180){
          this.autoPrintPageValue='3小时'
      }
      if(this.newOrderPrintVal==360){
          this.autoPrintPageValue='6小时'
      }
      if(this.newOrderPrintVal==720){
      this.autoPrintPageValue='12小时'
      }
    }else{
      this.newOrderPrint='0'
      this.autoPrintPageValue='30分钟'
    }
  })
    this.cetatAutoPrintRadio=this.autoPrintRadio
    this.cetatnewOrderPrint=this.newOrderPrint
    this.cetatautoPrintPageValue=this.autoPrintPageValue
  },
    // 自动打印保存按钮
    handleAutoPrintConfirm(){
      let delayTime=''
        if(this.autoPrintPageValue=='30分钟'){
          delayTime=30
        }
        if(this.autoPrintPageValue=='1小时'){
          delayTime=60
        }
        if(this.autoPrintPageValue=='3小时'){
          delayTime=180
        }
        if(this.autoPrintPageValue=='6小时'){
          delayTime=360
        }
          if(this.autoPrintPageValue=='12小时'){
          delayTime=720
          }
      let params={
        auto_print:this.autoPrintRadio,
        auto_print_delay:this.newOrderPrint==0?this.newOrderPrint:delayTime,
        order_sort:this.$store.state.setting.config_info.order_sort
      }
        this.$store.dispatch("setting/editConfig", params).then((res) => {
        if (res.code == 0) {
          this.$message({ message: "保存成功", type: "success",duration:1000 });
        } else {
          this.$message({ message: res.message, type: "error" });
        }
      });
      this.dialogAutoPrint=false
    },
    // 自动打印取消按钮
    handleAutoPrintCancel(){
      this.$store.commit('batchprint/SAVE',{dialogAutoPrint:false})
      this.autoPrintRadio=this.cetatAutoPrintRadio
      this.newOrderPrint=this.cetatnewOrderPrint
      this.autoPrintPageValue=this.cetatautoPrintPageValue
    },
  },
}
</script>

<style lang="less" scoped>
.advanced-setting /deep/ .el-dialog{
  background-color: #fff;
  .el-icon-close:before {
    content:''
  }
  .el-dialog__header {
    background-color: #FD7225;
    padding: 5px 20px 6px;
  }
 .el-dialog__body {
  padding: 10px 20px;
  padding-bottom: 0px;
 }
 .el-dialog__footer {
  padding: 0px 20px 7px !important;
 }
 
  .setting-content,.auto-print-content {
    display: flex;
    box-sizing: border-box;
    border-bottom: 1px solid #939191;
    border-top: 1px solid #939191;
    padding: 20px 8px;
    // height: 119px;
    // line-height: 45px;
    .el-input__inner {
      height: 25px;
      text-align: center;
    }
    .el-input__icon {
      line-height: 30px;
    }
    .el-checkbox__inner {
      border: 1px solid black;
    }
  }
  .heightSetting-confirm-btn,.heightSetting-cancel-btn {
     font-size: 15px;
      line-height: 15px;
      background-color: #FD7225;
      color: #fff;
      display: inline-block;
      border-radius: 3px;
      margin: 7px 12px 7px 0px;
      cursor: pointer;
     width:52px;
     height: 25px;
     line-height: 25px;
     text-align: center;
    // background-color: #FD7225;
  }
  .setting-confirm-btn,.setting-cancel-btn {
      font-size: 15px;
      line-height: 15px;
      background-color: #FD7225;
      color: #fff;
      display: inline-block;
      padding: 6px 15px;
      border-radius: 4px;
      margin: 10px;
      cursor: pointer;
    }
    .heightSetting-cancel-btn {
      background-color: #fff;
      color: #333;
      border: 1px solid #333;
    }
    .setting-cancel-btn {
      background-color: #fff;
      color: #333;
      border: 1px solid #333;
    }
  .auto-print-content {
    display: block;
    
  }
}

/deep/ .auto-print-content .el-icon-arrow-up:before {
    color:rgb(0, 0, 0);
  }
  /deep/.auto-print-content .el-input__inner{
    background-color: rgb(240, 237, 241);
    border-color: rgb(0, 0, 0);
    color:rgb(0, 0, 0);
  }
</style>